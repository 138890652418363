<template>
  <v-dialog v-model="dialogState" persistent max-width="800">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-send</v-icon>
          {{$vuetify.lang.t('$vuetify.action.send')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.email')+ ' *'"
                v-model="newItem.mailto"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="sendInvoice">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.send')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import Api from "@/services/api";
import ManagerHelper from "@/helpers/manager";
export default {
  data: function() {
    return {
      dialogState: false,
      newItem: {
        invoice_id: false,
        mailto: false
      },
      validator: ManagerHelper.validatorModel(),
      roles: []
    };
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    sendInvoice() {
      // this.newItem["password"] = "test";
      // Api.manager("create", this.newItem)
      //   .then(({ data }) => {
      //     this.$toast.ok(
      //       this.$vuetify.lang.t("$vuetify.manager.account_created") +
      //         " " +
      //         this.$vuetify.lang.t("$vuetify.manager.activation_email_sent")
      //     );

      //     this.$emit("managerAdded", data);
      //     this.closePopup();
      //   })
      //   .catch(error => {
      //     if (
      //       error.response.status == 400 &&
      //       typeof error.response.data.field !== "undefined"
      //     ) {
      //       this.validator.username.error_show = true;
      //       this.validator.username.error_msg = this.$i18n.messages[
      //         this.$i18n.locale
      //       ]["$vuetify"]["service_field_errors"][error.response.data.error];
      //     }
      //   });
    }
  },
  watch: {
    sendInvoicePopup: function(newVal) {
      this.dialogState = newVal;
      this.newItem.mailto = this.$parent.invoice.company.email
    }
  },
  props: ["sendInvoicePopup"]
};
</script>
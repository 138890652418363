<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.edit") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start" class="mt-3">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.tables.amount')"
                v-model="newItem.amount"
                type="number"
              ></v-text-field>
            </div>
            <div
              class="col-xs-12 col-sm-12 col-md-6"
              v-if="newItem.invoice_type == 'barcode'"
            >
              <v-text-field
                :label="
                  $vuetify.lang.t('$vuetify.company.barcode_count') + ' *'
                "
                v-model="newItem.barcode_count"
                type="number"
              ></v-text-field>
            </div>
          </v-row>
          <v-row align="start" class="mt-3">
            <v-col>
              <v-autocomplete
                v-model="newItem.template"
                :items="$vuetify.lang.t('$vuetify.invoice_templates')"
                item-text="value"
                item-value="key"
                return-object
                :label="$vuetify.lang.t('$vuetify.company.invoice_template') + ' *'"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="start" class="mt-3">
            <v-col>
              <v-textarea
                v-model="newItem.comment"
                :label="$vuetify.lang.t('$vuetify.company.comment')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="updateInvoice">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
export default {
  data: function() {
    return {
      dialogState: false,
      newItem: {
        barcode_count: null,
        amount: null,
        company_id: null,
        id: null,
        invoice_type: null,
        currency: null,
        comment: null,
        template: "ka_gel",
      },
    };
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    updateInvoice() {
      this.newItem.template = this.newItem.template.key;
      Api.invoice("update", this.newItem)
        .then(() => {
          this.$toast.ok(this.$vuetify.lang.t("$vuetify.invoice.updated"));
          this.$parent.reload += 1;
          this.closePopup();
        })
        .catch((error) => {
          if (
            error.response.status == 400 &&
            typeof error.response.data.field !== "undefined"
          ) {
            this.validator.username.error_show = true;
            this.validator.username.error_msg = this.$i18n.messages[
              this.$i18n.locale
            ]["$vuetify"]["service_field_errors"][error.response.data.error];
          }
        });
    },
  },
  watch: {
    "newItem.barcode_count": function(newVal) {
      this.newItem.amount = newVal * 35;
    },
    editInvoicePopup: function(newVal) {
      this.dialogState = newVal;
      this.newItem.amount = this.$parent.invoice.amount;
      this.newItem.comment = this.$parent.invoice.comment;
      this.newItem.barcode_count = this.$parent.invoice.barcode_count;
      this.newItem.company_id = this.$parent.invoice.company.id;
      this.newItem.id = this.$parent.invoice.id;
      this.newItem.invoice_type = this.$parent.invoice.invoice_type;
      this.newItem.currency = this.$parent.invoice.currency;
      this.newItem.template = this.$parent.invoice.template;
    },
  },
  props: ["editInvoicePopup"],
};
</script>
